import React from 'react';
import PropTypes from 'prop-types';
import styles from './SectionLabel.module.scss';

const SectionLabel = ({
  label,
}) => (
  <h2 className={styles.label}>{label}</h2>
);

SectionLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export default SectionLabel;
