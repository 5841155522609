import React from 'react';
import PropTypes from 'prop-types';
import styles from './Carousel.module.scss';


const CarouselContainer = (props) => {
  const {
    sliding,
    className,
    children,
    direction,
  } = props;

  const wrapperClasses = [
    styles.containerWrapper,
    className,
  ].join(' ');

  let containerClasses = [
    styles.container,
    sliding && styles.sliding,
  ];

  if (!sliding) {
    containerClasses.push(styles.notSliding);
  } else if (direction === 'prev') {
    containerClasses.push(styles.prev);
  }

  containerClasses = containerClasses.join(' ');

  return (
    <div className={wrapperClasses}>
      <div
        className={containerClasses}
      >
        {children}
      </div>
    </div>
  );
};

CarouselContainer.propTypes = {
  children: PropTypes.node.isRequired,
  sliding: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

CarouselContainer.defaultProps = {
  className: null,
};

export default CarouselContainer;
