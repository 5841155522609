import React from 'react';
import PropTypes from 'prop-types';
import CarouselContainer from './CarouselContainer';
import CarouselSlot from './CarouselSlot';
import styles from './Carousel.module.scss';

import leftIcon from '../../images/icons/Timberland-Nav-Left.svg';
import rightIcon from '../../images/icons/Timberland-Nav-Right.svg';


export default class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.getOrder = this.getOrder.bind(this);
    this.doSliding = this.doSliding.bind(this);
    this.touchStart = this.touchStart.bind(this);
    this.touchEnd = this.touchEnd.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.prevSlide = this.prevSlide.bind(this);
    this.state = {
      sliding: false,
      direction: 'next',
      position: 0,
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.controlled && props.indexChanged) {
      const { startingIndex } = props;
      return { position: startingIndex };
    }
    return {};
  }

  getOrder(itemIndex) {
    const { children } = this.props;
    const { position } = this.state;
    const childrenArray = React.Children.toArray(children);
    const numItems = childrenArray.length || 1;
    // if (itemIndex - position < 0) {
    //   return numItems - Math.abs(itemIndex - position);
    // }
    // return itemIndex - position;
    return (((numItems + 1) - position) + itemIndex) % numItems;
  }

  setActive(index) {
    const { position } = this.state;
    if (index > position) {
      this.doSliding('next', index);
    } else if (index < position) {
      this.doSliding('prev', index);
    } else {
      // no nothing
    }
  }

  doSliding(direction, position) {
    const { controlled, indexChanged } = this.props;
    if (controlled && indexChanged) {
      indexChanged(position);
    }
    this.setState({
      sliding: true,
      direction,
      position,
    });

    setTimeout(() => {
      this.setState({
        sliding: false,
      });
    }, 50);
  }

  nextSlide() {
    const { children } = this.props;
    const { position } = this.state;
    const childrenArray = React.Children.toArray(children);
    const numItems = childrenArray.length || 1;
    this.doSliding('next', position === numItems - 1 ? 0 : position + 1);
  }

  prevSlide() {
    const { children } = this.props;
    const { position } = this.state;
    const childrenArray = React.Children.toArray(children);
    const numItems = childrenArray.length;
    this.doSliding('prev', position === 0 ? numItems - 1 : position - 1);
  }

  touchStart(e) {
    this.setState({
      touchStartPos: e.changedTouches[0].clientX,
    });
  }

  touchEnd(e) {
    const { touchStartPos } = this.state;
    const touchEndPos = e.changedTouches[0].clientX;
    const touchWidth = e.target.offsetWidth;
    if (touchStartPos < touchEndPos) {
      if ((touchEndPos - touchStartPos) > (touchWidth / 4)) {
        this.prevSlide();
      }
    } else if ((touchStartPos - touchEndPos) > (touchWidth / 4)) {
      this.nextSlide();
    }
    this.setState({
      touchStart: 0,
    });
  }


  render() {
    const { navigation, lightDots, className, children } = this.props;
    const { sliding, direction } = this.state;
    const childrenArray = React.Children.toArray(children);
    const { position } = this.state;
    const dotWrapperStyles = [styles.dotWrapper, lightDots && styles.dotWrapperLight].join(' ');
    const dotStyles = index => [styles.dot, index === position ? styles.activeDot : null].join(' ');
    const isNext = index => (index === position + 1 || (position === (childrenArray.length - 1) && index === 0));

    return (
      <div onTouchStart={this.touchStart} onTouchEnd={this.touchEnd} className={className}>
        <div className={styles.controlWrapper}>
          {navigation && (
            <div
              className={[styles.navButton, styles.navLeft].join(' ')}
              onClick={this.prevSlide}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  e.target.click();
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img src={leftIcon} alt="left-icon" />
            </div>
          )}
          <CarouselContainer
            sliding={sliding}
            direction={direction}
            className={styles.carouselContainer}
          >
          {childrenArray.map((child, index) => (
            <CarouselSlot
              key={child.key}
              order={this.getOrder(index)}
              active={index === position}
              next={isNext(index)}
              >
              {child}
            </CarouselSlot>
          ))}
          </CarouselContainer>
          {navigation && (
            <div
              className={[styles.navButton, styles.navRight].join(' ')}
              onClick={this.nextSlide}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  e.target.click();
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img src={rightIcon} alt="right-icon" />
            </div>
          )}
        </div>
        <div className={dotWrapperStyles}>
          {childrenArray.map((child, index) => (
            <div
              key={child.key}
              className={dotStyles(index)}
              onClick={() => this.setActive(index)}
              onKeyPress={e => (e.key === 'Enter' ? this.setActive(index) : null)}
              role="radio"
              aria-checked={index === position}
              tabIndex="0"
            />
          ))}
        </div>
      </div>
    );
  }
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  navigation: PropTypes.bool,
  lightDots: PropTypes.bool,
  controlled: PropTypes.bool,
  indexChanged: PropTypes.func,
  startingIndex: PropTypes.number,
};

Carousel.defaultProps = {
  className: null,
  navigation: false,
  lightDots: false,
  controlled: false,
  indexChanged: null,
  startingIndex: 0,
};
