import React from 'react';
import PropTypes from 'prop-types';
import Carousel from '../Carousel/Carousel';
import FeaturedPro from './FeaturedPro';
import styles from './FeaturedPros.module.scss';

const FeaturedPros = ({ pros }) => {
  const renderFeaturedPro = ({ title, iconUrl, description }) => (
    <div key={title} className={styles.listItem}>
      <FeaturedPro
        title={title}
        description={description}
        iconUrl={iconUrl}
      />
    </div>
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.small}>
        <Carousel>
          {pros.map(renderFeaturedPro)}
        </Carousel>
      </div>
      <div className={styles.medium}>
        {pros.map(renderFeaturedPro)}
      </div>
    </div>
  );
};

FeaturedPros.propTypes = {
  pros: PropTypes.arrayOf(
    PropTypes.shape({
      iconUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
};

export default FeaturedPros;
