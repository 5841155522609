import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styles from './Gallery.module.scss';

const GalleryPicture = ({
  title,
  fluid,
  description,
  overlay,
  lightText,
}) => (
  <div
    className={[
      styles.imageWrapper,
      overlay && styles.withOverlay,
      lightText && styles.withLightText,
    ].join(' ')}
  >
    <Img
      fluid={fluid}
      alt={title}
    />
    <div className={styles.imageOverlay}>
      <h3 className={[styles.imageTitle, styles.h4].join(' ')}>{title}</h3>
      <div className={styles.imageDescription}>{description}</div>
    </div>
  </div>
);

GalleryPicture.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  overlay: PropTypes.bool,
  lightText: PropTypes.bool,
  fluid: PropTypes.object.isRequired,
};

GalleryPicture.defaultProps = {
  overlay: false,
  lightText: false,
};

export default GalleryPicture;
