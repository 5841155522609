import React from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransitionMotion, spring } from 'react-motion';
import styles from './Modal.module.scss';


export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentWillMount() {
    this.modalRoot = typeof window !== 'undefined'
      ? document.getElementById('modal')
      : null;
  }

  render() {
    const {
      show,
      children,
      backDropClick,
    } = this.props;
    const willLeave = () => ({ opacity: spring(0), y: spring(-200) });
    const willEnter = () => ({ opacity: 0, y: -400 });

    if (!this.modalRoot) {
      return null;
    }

    if (show) {
      setTimeout(() => this.modalRef.current.focus(), 100);
    }

    return ReactDOM.createPortal((
      <TransitionMotion
        willLeave={willLeave}
        willEnter={willEnter}
        styles={
          show ? [{
            key: 'a',
            style: {
              opacity: spring(1),
              y: spring(0),
            },
          }] : []
        }
      >
        {(items) => {
          if (items.length) {
            const { key, style } = items[0];
            return (
              <div
                className={styles.backdrop}
                key={key}
                style={{ opacity: style.opacity }}
                onClick={e => backDropClick()}
              >
                <div
                  className={styles.modal}
                  style={{
                    position: 'relative',
                    bottom: style.y,
                    opacity: style.opacity
                  }}
                  onClick={e => e.stopPropagation()}
                  ref={this.modalRef}
                  tabIndex="-1"
                >
                  <FontAwesomeIcon
                    className={styles.close}
                    icon={['fas', 'times']}
                    onClick={e => backDropClick()}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        backDropClick()
                      }
                    }}
                    tabIndex="0"
                    aria-label="close modal"
                    role="button"
                  />
                  {children}
                </div>
              </div>
            );
          }
          return null;
        }}
      </TransitionMotion>
    ), this.modalRoot);
  }
}
