import React from 'react';
import PropTypes from 'prop-types';
import styles from './FeaturedPros.module.scss';

const FeaturedPro = ({ title, iconUrl, description }) => (
  <div className={styles.proWrapper}>
    <div className={styles.iconWrapper}>
      <img src={iconUrl} alt={`${title} icon`} />
    </div>
    <h3 className={[styles.proTitle, styles.h4].join(' ')}>{title}</h3>
    <span className={styles.proDescription}>{description}</span>
  </div>
);

FeaturedPro.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
};

export default FeaturedPro;
