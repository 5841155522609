import React from 'react';
import PropTypes from 'prop-types';
import styles from './FeatureList.module.scss';

const FeatureList = ({ features }) => (
  <ul className={styles.list}>
    {features.map(({ label, iconUrl }) => (
      <li key={label} className={styles.listItem}>
        {iconUrl && <span className={styles.icon}><img src={iconUrl} alt={`${label} icon`} /></span>}
        {label && <span className={styles.label}>{label}</span>}
      </li>
    ))}
  </ul>
);


FeatureList.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      iconUrl: PropTypes.string,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FeatureList;
