import React from 'react';
import PropTypes from 'prop-types';
import styles from './Carousel.module.scss';

const CarouselSlot = ({
  active,
  next,
  order,
  children,
}) => {
  const slotStyles = [styles.slot, active && styles.activeSlot, next && styles.nextSlot].join(' ');
  return (
    <div className={slotStyles} style={{ order }}>
      {children}
    </div>
  );
};

CarouselSlot.propTypes = {
  order: PropTypes.number.isRequired,
  active: PropTypes.bool,
  next: PropTypes.bool,
};

CarouselSlot.defaultProps = {
  active: false,
  next: false,
};

export default CarouselSlot;
