import React from 'react';
import PropTypes from 'prop-types';
import Carousel from '../Carousel/Carousel';
import GalleryPicture from './GalleryPicture';
import Modal from '../Modal/Modal';
import styles from './Gallery.module.scss';

export default class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.toggleLightBox = this.toggleLightBox.bind(this);
    this.state = {
      lightBoxOpen: false,
      lightBoxIndex: 0,
    };
  }

  toggleLightBox(index) {
    this.setState(state => ({
      lightBoxOpen: !state.lightBoxOpen,
      lightBoxIndex: index || 0,
    }));
  }

  render() {
    const { galleryAssets } = this.props;
    const { lightBoxOpen, lightBoxIndex } = this.state;
    const imageClasses = [
      styles.listItem,
      (galleryAssets.length % 3 > 0) && styles.widerImage,
    ].join(' ');

    const renderImage = ({
      asset,
      lightBox,
      lightText,
      overlay,
      tabing,
    }, index) => (
      <div
        key={asset.id}
        className={imageClasses}
        onClick={() => lightBox && this.toggleLightBox(index)}
        tabIndex={tabing ? '0' : '-1'}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            e.target.click();
          }
        }}
      >
        <GalleryPicture
          title={asset.title}
          description={asset.description}
          fluid={asset.fluid}
          overlay={overlay}
          lightText={lightText}
        />
      </div>
    );

    const lightBoxClasses = [
      styles.lightBox,
      lightBoxOpen && styles.open,
    ].join(' ');

    return (
      <div className={styles.wrapper}>
        <div className={styles.small}>
          <Carousel>
            {galleryAssets.map((asset, index) => renderImage({
              asset,
              lightBox: false,
              lightText: false,
              overlay: false,
              tabing: true,
            }, index))}
          </Carousel>
        </div>
        <div className={styles.medium}>
          {galleryAssets.map((asset, index) => renderImage({
            asset,
            lightBox: true,
            lightText: false,
            overlay: true,
            tabing: true,
          }, index))}
        </div>
        <div className={lightBoxClasses}>
          <Modal
            show={lightBoxOpen}
            closeClick={this.toggleLightBox}
            backDropClick={this.toggleLightBox}
          >
            <Carousel
              navigation
              controlled
              startingIndex={lightBoxIndex}
              indexChanged={index => this.setState({ lightBoxIndex: index })}
            >
              {galleryAssets.map((asset, index) => renderImage({
                asset,
                lightBox: false,
                lightText: true,
                overlay: false,
                tabing: false,
              }, index))}
            </Carousel>
          </Modal>
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {
  galleryAssets: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
};
